<template>
  <div class="main">
    <div class="banner">
      <div class="joinus">
        <div class="p"></div>
        <div class="text">
          <p>有一个优秀的团队时刻在等待优秀的您，不是想找几百个人一起上班</p>
          <p>而是找志同道合的人一起奋斗！</p>
          <p>全新的起点，全新的开始，让我们.携手迎接未来的挑战与机遇！</p>
        </div>
      </div>
    </div>
    <!-- banner -->
    <div class="job">
      <div class="group">
        <div class="card" v-for="i in 9" :key="i">
          <div class="top">
            <div class="bold">产品经理</div>
            <div>山东·济南·市中区</div>
          </div>
          <div>整理客户需求，提升用户体验，增加产品价值，完善产品体系</div>
          <div class="btn" @click="showModel = true">了解详情</div>
        </div>
      </div>
    </div>
    <template v-if="showModel">
      <div class="bg">
        <div class="model">
          <div class="title">
            <div class="bold">产品经理</div>
            <div>山东·济南·市中区</div>
          </div>
          <div class="text">
            <div class="bold">工作职责:</div>
            <div>
              1 . 负责相关需求收集、管理、评估及落实;<br />
              2
              .根据用户需求及产品现状，规划产品方案，并管理和引导客户预期，以最优方式推进产品落地;<br />
              3 . 编写常用产品文档，如PRD、原型设计、高保真原型、PPT等;<br />
              4 .独立与相关负责人进行功能对接及方案、原型评审;<br />
              5 .给开发团队讲需、解决开发团队疑问;<br />
              6 .跟进开发团队进度与质量，保证产品按时上线。<br />
              7 .跟进上线功能运营数据，提出优化建议，梳理需求进行下一次迭代。<br />
            </div>
          </div>
          <div class="text">
            <div class="bold">工作职责:</div>
            <div>
              1 . 负责相关需求收集、管理、评估及落实;<br />
              2
              .根据用户需求及产品现状，规划产品方案，并管理和引导客户预期，以最优方式推进产品落地;<br />
              3 . 编写常用产品文档，如PRD、原型设计、高保真原型、PPT等;<br />
              4 .独立与相关负责人进行功能对接及方案、原型评审;<br />
              5 .给开发团队讲需、解决开发团队疑问;<br />
              6 .跟进开发团队进度与质量，保证产品按时上线。<br />
              7 .跟进上线功能运营数据，提出优化建议，梳理需求进行下一次迭代。<br />
            </div>
          </div>
          <div class="text">
            <div class="bold">福利待遇:</div>
            <div class="welfare">
              <div v-for="i in 5" :key="i">带薪年假</div>
            </div>
          </div>
          <div class="bottom">简历可发送至HR邮箱：zhaopin@xetec.cn</div>
          <div class="btn" @click="showModel = false">关闭</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModel: false,
    
    };
  },
  mounted() {
    //设置遮罩层高度
  },
};
</script>

<style lang="scss" scoped>
main {
  position: relative;
}
.banner {
  overflow: hidden;
  width: 100%;
  height: 900px;
  background: url("../assets/img/join/banner_join.jpg") no-repeat;
  background-size: 2560px 900px;
  background-position: center;
  position: relative;
  .joinus {
    width: 1260px;
    height: 213px;
    background: url("../assets/img/join/join_bg.png");
    position: absolute;
    bottom: 0;
    left: calc(50% - 1260px / 2);
    padding-top: 205px;
    .p {
      width: 152px;
      height: 36px;
      background: url("../assets/img/join/joinus.png");
      margin: auto;
    }
    .text {
      margin: 40px auto;
      width: 540px;
      font-size: 18px;
      line-height: 36px;
      color: #616161;
      text-align: center;
      p {
        margin: 0;
      }
    }
  }
}
.job {
  padding-top: 30px;
  background: #f5f9fc;
  .group {
    width: 1200px;
    background-color: #ffffff;
    box-shadow: 0px 0px 54px 0px rgba(65, 65, 65, 0.05);
    border-radius: 3px 3px 0px 0px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 380px);
    grid-template-rows: repeat(3, 190px);
    grid-gap: 30px;
    padding: 30px;
    .card {
      border-radius: 3px;
      border: solid 1px #f1f1f1;
      padding: 20px;
      font-size: 14px;
      color: #818181;
      position: relative;
      top: 0;
      transition: 0.2s;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 14px;
        margin-bottom: 25px;
        color: #2397f3;
        .bold {
          font-size: 22px;
          color: #000000;
        }
      }
      .btn {
        width: 120px;
        height: 36px;
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #1b65fb;
        background-color: #ffffff;
        box-shadow: 0px 0px 8px 0px rgba(28, 101, 251, 0.4);
        border-radius: 20px;
        border: solid 2px #2397f3;
        line-height: 36px;
        text-align: center;
        transition: 0.2s;
      }
      &:hover {
        top: -10px;
        .btn {
          background-image: linear-gradient(45deg, #2397f3 0%, #1b65fb 100%);
          color: #fff;
        }
      }
    }
  }
}
.model {
  position: relative;
  margin: 120px auto;
  width: 900px;
  background-color: #ffffff;
  border-radius: 3px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 14px;
    color: #2397f3;
    margin: 25px;
    .bold {
      font-size: 26px;
      color: #000000;
      font-weight: bold;
    }
  }
  .text {
    font-size: 14px;
    line-height: 22px;
    color: #717171;
    margin: 25px;
    .bold {
      color: #2397f3;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .welfare {
      display: flex;
      div {
        background-color: #fcf0df;
        border-radius: 3px;
        font-size: 14px;
        color: #d19444;
        padding: 6px;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .bottom {
    font-size: 14px;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
    width: 900px;
    height: 50px;
    background-image: linear-gradient(10deg, #2397f3 0%, #1b65fb 100%);
    border-radius: 0px 0px 3px 3px;
    font-weight: bold;
  }
  .btn {
    position: absolute;
    bottom: -82px;
    left: calc(50% - 60px);
    width: 120px;
    height: 40px;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background-color: #878787;
    box-shadow: 0px 0px 8px 0px rgba(28, 101, 251, 0.4);
    border-radius: 20px;
    border: solid 2px #ffffff;
  }
}
.bg {
  position: absolute;
  overflow-y: scroll;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background:rgba(33, 33, 33, 0.9);
}
</style>